// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

// ** Axios Imports
import { get } from '@src/utility/api_helper'
import { getFirebaseBackend } from '@utility/FirebaseHelper'
import * as url from '@utility/URLHelper'

const config = useJwt.jwtConfig

export const getProfile = createAsyncThunk('user/getProfile', async () => {
  const response = await get(url.GET_PROFILE)
  console.log(response)
  return response.data
})

export const firebaseLogout = createAsyncThunk('user/logout', async () => {
  await getFirebaseBackend.logoutUser()
  return true
})

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    validateError: null,
    profile: null
  },
  reducers: {
    handleLogin: async (state, action) => {
      state.userData = action.payload
      //state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      //state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      //localStorage.setItem('userData', JSON.stringify(action.payload))
      //localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
      //localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
    },
    handleLogout: async (state) => {
      state[config.storageTokenKeyName] = null
      //state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      //await fireBaseBackend.logoutUser()
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getProfile.fulfilled, (state, action) => {
        state.profile = action.payload
      })
      .addCase(firebaseLogout.fulfilled, (state) => {
        state.profile = null
      })
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
