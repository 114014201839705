//LOGIN
export const POST_VALIDITY = '/v1.0/auth/validity'
export const GET_PROFILE = '/v1.0/auth/profile'
export const USER_ACCOUNT_ACCESS = '/v1.0/auth/user/access'
export const USER_ACCOUNT_REQUEST = '/v1.0/auth/user/request'
export const USER_ACCOUNT_GRANT = '/v1.0/auth/user/grant'
export const USER_ACCESS_REVOKE = '/v1.0/auth/access/revoke'
export const USER_ACCESS_GRANT = '/v1.0/auth/access/grant'

//TRANSACTIONS
export const GET_TRANSACTIONS = '/v1.0/transactions/list'
export const UPDATE_TRANSACTIONS = '/v1.0/transactions/update'
export const LINK_TRANSACTIONS = '/v1.0/transactions/link'
export const REVERT_TRANSACTIONS = '/v1.0/transactions/revert'
export const IGNORE_TRANSACTIONS = '/v1.0/transactions/ignore'
export const DELETE_TRANSACTIONS = '/v1.0/transactions/delete'
export const UNDO_IGNORE_TRANSACTIONS = '/v1.0/transactions/ignore/undo'
export const UNDO_DELETE_TRANSACTIONS = '/v1.0/transactions/delete/undo'

// GET LINK FOR UPLOADING FILE
export const UPLOAD_LINK = '/v1.0/exchanges/upload/link'
export const SAVE_ENTRY_BACKEND = '/v1.0/exchanges/upload/done'
export const GET_UPLOADS = '/v1.0/exchanges/upload/get'

//ADD EXCHANGE DATA
export const ADD_EXCHANGE = '/v1.0/exchanges/add'
export const GET_EXCHANGE = '/v1.0/exchanges/get'
export const GET_USER_COINS = '/v1.0/exchanges/coins'
export const GET_USER_FINANCIAL_YEAR = '/v1.0/exchanges/years'
export const RECALCULATE_EXCHANGE = '/v1.0/exchanges/recalculate'

//GET REPORTS
export const GET_USER_ACCOUNT_LEDGER = '/v1.0/reports/ledger'
export const GET_USER_ACCOUNT_TRANSACTION = '/v1.0/reports/transactions'
export const GET_USER_ACCOUNT_BALANCE = '/v1.0/reports/balance'
export const GET_USER_ACCOUNT_STATS = '/v1.0/reports/stats'
