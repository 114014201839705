// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import * as url from '@utility/URLHelper'
import { post } from '@src/utility/api_helper'

import _ from 'lodash'

export const getTransactionData = createAsyncThunk('transactions/getData', async (params) => {
  const response = await post(`${url.GET_TRANSACTIONS}/${params.limit}/${params.skip}`, params, { authorization: localStorage.getItem('authUser') })
  console.log(response)
  return response
})

export const updateTransactionData = createAsyncThunk('transactions/updateData', async (params) => {
  const response = await post(url.UPDATE_TRANSACTIONS, params, { authorization: localStorage.getItem('authUser') })
  console.log(response)
  return response
})

export const revertTransactionData = createAsyncThunk('transactions/revertData', async (params) => {
  const response = await post(url.REVERT_TRANSACTIONS, params, { authorization: localStorage.getItem('authUser') })
  console.log(response)
  return response
})

export const linkTransactionData = createAsyncThunk('transactions/linkData', async (params) => {
  const response = await post(url.LINK_TRANSACTIONS, params, { authorization: localStorage.getItem('authUser') })
  console.log(response)
  return response
})

export const ignoreTransactionData = createAsyncThunk('transactions/ignoreData', async (params) => {
  const response = await post(url.IGNORE_TRANSACTIONS, params, { authorization: localStorage.getItem('authUser') })
  console.log(response)
  return response
})

export const deleteTransactionData = createAsyncThunk('transactions/deleteData', async (params) => {
  const response = await post(url.DELETE_TRANSACTIONS, params, { authorization: localStorage.getItem('authUser') })
  console.log(response)
  return response
})

export const undoIgnoreTransactionData = createAsyncThunk('transactions/undoIgnoreData', async (params) => {
  const response = await post(url.UNDO_IGNORE_TRANSACTIONS, params, { authorization: localStorage.getItem('authUser') })
  console.log(response)
  return response
})

export const undoDeleteTransactionData = createAsyncThunk('transactions/undoDeleteData', async (params) => {
  const response = await post(url.UNDO_DELETE_TRANSACTIONS, params, { authorization: localStorage.getItem('authUser') })
  console.log(response)
  return response
})

export const getProfile = createAsyncThunk('users/profile', async () => {
  const response = await post(url.GET_PROFILE, null, { authorization: localStorage.getItem('authUser') })
  return response
})

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState: {
    transactionData: [],
    total: 0,
    coins: [],
    exchanges: [],
    params: {},
    allData: [],
    transaction_nos: null
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getTransactionData.fulfilled, (state, action) => {
      console.log(action.payload.body)
      const data = action.payload.body
      state.transactionData = data.map((transaction) => ({
        ...transaction,
        reference_transactions: _.sortBy(transaction.reference_transactions, ['currency', 'transaction_timestamp'])
      }))
    }).addCase(updateTransactionData.fulfilled, (state, action) => {
      const data = action.payload.body
      state.transactionData = state.transactionData.map((transaction) => {
        if (transaction.seqid === data.seqid) {
          return data
        } else {
          return { ...transaction }
        }
      })
    }).addCase(revertTransactionData.fulfilled, (state, action) => {
      const data = action.payload.body
      state.transactionData = state.transactionData.map((transaction) => {
        if (transaction.seqid === data.seqid) {
          return data
        } else {
          return { ...transaction }
        }
      })
    }).addCase(linkTransactionData.fulfilled, (state, action) => {
      const data = action.payload.body
      state.transactionData = state.transactionData.map((transaction) => {
        if (transaction.seqid === data.seqid) {
          return data
        } else {
          return { ...transaction }
        }
      })
    }).addCase(ignoreTransactionData.fulfilled, (state, action) => {
      const data = action.payload.body
      state.transactionData = state.transactionData.map((transaction) => {
        if (transaction.seqid === data.seqid) {
          return data
        } else {
          return { ...transaction }
        }
      })
    }).addCase(deleteTransactionData.fulfilled, (state, action) => {
      const data = action.payload.body
      state.transactionData = state.transactionData.filter((transaction) => transaction.seqid !== data.seqid)
    }).addCase(undoIgnoreTransactionData.fulfilled, (state, action) => {
      const data = action.payload.body
      state.transactionData = state.transactionData.map((transaction) => {
        if (transaction.seqid === data.seqid) {
          return data
        } else {
          return { ...transaction }
        }
      })
    }).addCase(undoDeleteTransactionData.fulfilled, (state, action) => {
      const data = action.payload.body
      state.transactionData = [data, ...state.transactionData]
    }).addCase(getProfile.fulfilled, (state, action) => {
      state.total = action.payload.body.transaction_nos
      state.coins = action.payload.body.coins && action.payload.body.coins.map((coin) => {
        return { value: coin, label: coin }
      })
      state.exchanges = action.payload.body.exchanges && action.payload.body.exchanges.map((exchange) => {
        return { value: exchange.exchange_id, label: `${exchange.pseudo_name} (${exchange.exchange_name})` }
      })
      //state.profile = action.payload.body
      //state.params = action.payload.params
      //state.allData = action.payload.allData
      //state.total = action.payload.totalPages
    })
  }
})

export default transactionsSlice.reducer
