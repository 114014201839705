import { initializeApp } from 'firebase/app'

// Add the Firebase products that you want to use
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  updateProfile,
  signOut
} from 'firebase/auth'
//import "firebase/compat/firestore"

const handleError = (error) => {
  // var errorCode = error.code;
  const errorMessage = error.message
  return errorMessage
}

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      initializeApp(firebaseConfig)
      const auth = getAuth()
      onAuthStateChanged(auth, (user) => {
        if (user) {
          if (user.emailVerified) {
            localStorage.setItem('authFirebaseUser', JSON.stringify(user))
          } else {
            localStorage.removeItem('authFirebaseUser')
          }
        } else {
          localStorage.removeItem('authFirebaseUser')
        }
      })
    }
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password, displayName) => {
    return new Promise((resolve, reject) => {
      const auth = getAuth()
      createUserWithEmailAndPassword(auth, email, password)
        .then(userCredential => {
          updateProfile(userCredential.user, { displayName })
          sendEmailVerification(userCredential.user)
          resolve(userCredential.user)
        },
        error => {
          reject(handleError(error))
        })
    })
  }

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      const auth = getAuth()
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          resolve(userCredential.user.getIdToken())
        },
        error => {
          reject(handleError(error))
        })
    })
  }

  /**
   * Login user with given details
   */
  getIDTokenUser = () => {
    return new Promise((resolve) => {
      const auth = getAuth()
      resolve(auth.currentUser.getIdToken())
    })
  }

  /**
   * forgot Password user with given details
   */
  forgotPassword = email => {
    return new Promise((resolve, reject) => {
      const auth = getAuth()
      sendPasswordResetEmail(auth, email, {
          url: `${window.location.protocol}//${window.location.host}/login`
        })
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(handleError(error))
        })
    })
  }

  /**
   * Logout the user
   */
  logoutUser = () => {
    return new Promise((resolve, reject) => {
      const auth = getAuth()
      signOut(auth)
        .then(() => {
          resolve(true)
          localStorage.removeItem('authFirebaseUser')
          localStorage.removeItem('authUser')
        })
        .catch(error => {
          reject(handleError(error))
        })
    })
  }

  /*addNewUserToFirestore = user => {
    const collection = firebase.firestore().collection("users")
    const { profile } = user.additionalUserInfo
    const details = {
      firstName: profile.given_name ? profile.given_name : profile.first_name,
      lastName: profile.family_name ? profile.family_name : profile.last_name,
      fullName: profile.name,
      email: profile.email,
      picture: profile.picture,
      createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
      lastLoginTime: firebase.firestore.FieldValue.serverTimestamp()
    }
    collection.doc(firebase.auth().currentUser.uid).set(details)
    return { user, details }
  }*/
}

let _fireBaseBackend = null

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config)
  }
  return _fireBaseBackend
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend
}

export { initFirebaseBackend, getFirebaseBackend }
