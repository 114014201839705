import axios from 'axios'

//apply base url for axios
const API_URL = process.env.REACT_APP_APISERVER

const axiosApi = axios.create({
  baseURL: API_URL
})

axiosApi.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export const get = async (url, config = {}) => {
  if (config.authorization) {
    axiosApi.defaults.headers.common['Authorization'] = config.authorization
  }
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export const post = async (url, data, config = {}) => {
  if (config.authorization) {
    axiosApi.defaults.headers.common['Authorization'] = config.authorization
  }
  return await axiosApi
    .post(url, new URLSearchParams({ ...data }), { ...config })
    .then(response => response.data)
}

export const put = async (url, data, config = {}) => {
  if (config.authorization) {
    axiosApi.defaults.headers.common['Authorization'] = config.authorization
  }
  return await axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export const del = async (url, config = {}) => {
  if (config.authorization) {
    axiosApi.defaults.headers.common['Authorization'] = config.authorization
  }
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
