// ** Reducers Imports
import layout from './layout'
import navbar from './reducers/navbar'
import auth from './reducers/authentication'
import transactions from '@src/views/AllTransactions/api'

const rootReducer = {
  auth,
  navbar,
  layout,
  transactions
}

export default rootReducer
